<template>
    <el-dialog
    v-model="dialogVisible"
    :title="type?'编辑角色':'添加角色'"
    width="40%">
    <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    :rules="rules"
    label-width="100px"
    size="large">
        <el-form-item label="角色名称" prop="roleName">
            <el-input v-model="ruleForm.roleName" />
        </el-form-item>
        <el-form-item label="描述" prop="description">
           <el-input type="textarea" v-model="ruleForm.description" />
        </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button color="#803a15" dark @click="formSubmit('ruleFormRef')">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { editRole, addRole } from '@/api/role.js'
export default {
    emits:['OK'],
    data(){
        return{
            dialogVisible:false,
            type:0,
            rolesOption:[],
            ruleForm:{
                roleName:'',
                description:''
            },
            rules:{
                roleName:{ required: true, message: '角色名不能为空', trigger: 'blur' },
            }
        }
    },
    mounted(){
        
    },
    methods:{
        open(type,data){
            this.type = type
            this.dialogVisible = true
            if(type == 1){
                this.ruleForm = {
                    id:data.id,
                    roleName:data.roleName,
                    description:data.description
                }
            }else{
                this.$nextTick(()=>{
                    this.$refs.ruleFormRef.resetFields() 
                })
            }
        },
        formSubmit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true
                    if(this.type == 0){
                        this.AddData(this.ruleForm)
                    }else{
                        this.EditData(this.ruleForm)
                    }
                } else {
                    return false;
                }
            });
        },
        AddData(data){
            addRole(data).then(r=>{
                this.loading = false
                if(r.code==200){
                    this.$message({
                        type: 'success',
                        message: '新增成功!'
                    });
                    this.$emit('OK',0)
                    this.dialogVisible = false
                }else{
                    this.$message({
                        type: 'warning',
                        message: '新增失败!'
                    });
                }
            }).catch(()=>{
                this.loading = false
            })
        },
        EditData(data){
            editRole(data).then(r=>{
                this.loading = false
                if(r.code==200){
                    this.$message({
                        type: 'success',
                        message: '编辑成功!'
                    });
                    this.$emit('OK',1)
                    this.dialogVisible = false
                }else{
                    this.$message({
                        type: 'warning',
                        message: '编辑失败!'
                    });
                }
            }).catch(()=>{
                this.loading = false
            })
        },
    }
}
</script>