import request from '@/utils/request'


export function RolesList() { //不带分页
  return request({
    url: '/sys/role/listRols',
    method: 'get',
  })
}


// 获取所有的Role
export function getRoles(data) { //带分页
  return request({
    url: '/sys/role/list',
    method: 'get',
    params:data
  })
}

export function addRole(data) {
  return request({
    url: '/sys/role/add',
    method: 'post',
    data
  })
}

export function delRole(id) {
  return request({
    url: `/sys/role/delete?id=${id}`,
    method: 'get',
  })
}

export function editRole(data) {
  return request({
    url: '/sys/role/update',
    method: 'post',
    data
  })
}

export function listPermission() {
  return request({
    url: '/sys/role/listPermission',
    method: 'get',
  })
}

export function bindPermission(data) {
  return request({
    url: '/sys/role/bindPermission',
    method: 'get',
    params:data
  })
}

export function listMenu() { //菜单列表
  return request({
    url: '/sys/role/listMenu',
    method: 'get',
  })
}

export function bindlistMenu(data) { //角色绑定菜单
  return request({
    url: '/sys/role/bindMenu',
    method: 'get',
    params:data
  })
}